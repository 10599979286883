.hs__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .hs__header-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 20em;
    text-align: right;
  }
  
  .hs__header-content h1 {
    font-family: 'Paytone One';
    font-weight: 600;
    font-size: 90px;
    line-height: 90px;
  }
  
  .hs__header-content p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: var(--color-text);
    text-align: right;
    margin-top: 1.5rem;
  }
  
  .hs__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .hs__header-content__input button {
    flex: 0.6;
    max-width: 150px;
    min-width: 120px;
    min-height: 40px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    background: #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
  }
  
  .hs__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hs__header-image img {
    margin-top: -8vh;
    width: 50vh;
    height: 50vh;
  }
  
  @media screen and (max-width: 1250px) {
    .hs__header-image img {
      margin-top: -10vh;
      width: 40vh;
      height: 40vh;
    }
  
    .hs__header-content h1 {
      font-size: 70px;
      line-height: 70px;
    }
  }
  
  @media screen and (max-width: 1080px) {
    .hs__header-content {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      margin-right: 15em;
      text-align: right;
    }
    
    .hs__header-image {
      width: 35vh;
      height: 35vh;
    }
  
    .hs__header-content h1 {
      font-family: 'Paytone One';
      font-weight: 600;
      font-size: 50px;
      line-height: 50px;
      letter-spacing: 2px;
    }
  }
  
  @media screen and (max-width: 950px) {
    .hs__header-content {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      margin-right: 15em;
      text-align: right;
      align-items: flex-end;
    }
  
    .hs__header-content p {
      max-width: 180px;
    }
  
    .hs__header-image {
      width: 25vh;
      height: 25vh;
    }
  }
  
  @media screen and (max-width: 820px) {
    .hs__header-content {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-right: 10em;
      text-align: right;
    }
  
    .hs__header-image img {
      margin-top: 0;
      width: 35vh;
      height: 35vh;
    }
  
    .hs__header-content h1 {
      font-size: 52px;
      line-height: 48px;
    }
  
    .hs__header-content p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: var(--color-text);
      text-align: right;
      margin-top: 1.5rem;
    }
  }
  
  @media screen and (max-width: 650px) {
    .hs__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .hs__header-content {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin-right: 0;
    }
  
    .hs__header-image img {
      margin-top: 0;
      width: 30vh;
      height: 30vh;
    }
  
    .hs__header-content p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: var(--color-text);
      text-align: center;
      margin-top: 1.5rem;
    }
  
    .hs__header-content__input {
      width: 100%;
      margin: 2rem 0 1rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  
  @media screen and (max-width: 490px) {
    .hs__header-content h1 {
      font-size: 52px;
      line-height: 48px;
    }
  
    .hs__header-content p {
      font-size: 14px;
      line-height: 24px;
    }
  
    .hs__header-content__input button {
      font-size: 12px;
      line-height: 16px;
    }
  }
  