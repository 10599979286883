.hs__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 4rem;
  }
  
  .hs__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .hs__navbar-links_logo {
    margin-right: 2rem;
    margin-left: -2rem;
  }
  
  .hs__navbar-links_logo img {
    width: 142.56px;
    height: 76.02px;
    cursor: pointer;
  }
  
  .hs__navbar-links_container {
    display: flex;
    flex-direction: row;
  }
  
  .hs__navbar-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
  
  .hs__navbar-links_container p,
  .hs__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0 1rem 0.2rem;
    cursor: pointer;
  }
  
  .hs__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .hs__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
  }
  
  .hs__navbar-menu svg {
    cursor: pointer;
    color: #fff;
  }
  
  .hs__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  
  .hs__navbar-menu_container p {
    margin: 1rem 0;
  }
  
  .hs__navbar-menu_container-links-social {
    display: none;
  }
  
  @media screen and (max-width: 950px) {
    .hs__navbar {
      padding: 2rem 2rem 4rem 2rem;
    }
  
    .hs__navbar-links_container {
      display: none;
    }
  
    .hs__navbar-links_logo {
      margin-right: 2rem;
    }
  
    .hs__navbar-menu {
      display: flex;
    }
  }
  
  @media screen and (max-width: 700px) {
    .hs__navbar {
      padding: 1rem 2rem 2rem 2rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .hs__navbar {
      padding: 1rem 2rem 2rem 2rem;
    }
  
    .hs__navbar-social {
      display: none;
    }
  
    .hs__navbar-menu_container {
      top: 20px;
    }
  
    .hs__navbar-menu_container-links-social {
      display: block;
      cursor: pointer;
    }
  }
  