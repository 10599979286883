.hs__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: -1rem;
}

.hs__brand div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 0rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 950px) {
    .hs__brand div {
        flex: 1;
        max-width: 150px;
        min-width: 90px;
        margin: 0rem;
        padding-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 650px) {
    .hs__brand {
        margin: 2rem 2rem 2rem 2rem;
    }
}