.hs__projects {
    display: flex;
    flex-direction: column;
}

.hs__projects button {
    background-color: transparent;
    width: 100px;
    border: none;
    justify-content: left;
    text-align: left;
}

.hs__projects-container_project {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.hs__projects-heading {
    width: 100%;
    text-align: left;
    padding-bottom: 3rem;
}

.hs__projects-heading h2 {
    font-family: 'Paytone One';
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
}

.hs__projects-container {
    display: flex;
    flex-direction: row;
}

.hs__projects-container_groupA {
    flex: 0.75;
    margin-right: 2rem;
}

.hs__projects-container_groupB {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 950px) {
    .hs__projects-heading {
        width: 100%;
        text-align: left;
        margin-top: 2rem;
        padding-bottom: 1rem;
    }

    .hs__projects-heading h2 {
        font-family: 'Paytone One';
        font-weight: 500;
        font-size: 32px;
        line-height: 42px;
    }
    
    .hs__projects-container {
        flex-direction: column-reverse;
    }

    .hs__projects-container_groupA {
        margin: 2rem 0;
    }

    .hs__projects-container_groupA .hs__projects-container_article {
        width: 48%;
    }
    .hs__projects-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }
    .hs__projects-container_groupA .hs__projects-container_article {
        width: 100%;
    }
}