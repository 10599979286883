.hs__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    margin: 1rem;
}

.hs__features-container__feature-title {
    flex: 1;
    max-width: 180px;
    padding-right: 20px;
}

.hs__features-container__feature-title h2 {
    font-family: 'Paytone One';
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0em;
    color: #fff;
}

.hs__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}

.hs__features-container_feature-text {
    flex: 2;
    min-width: 290px;
    display: flex;
    padding-top: 10px;
}

.hs__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-text);
    letter-spacing: 0.6px;
}

@media screen and (max-width: 750px) {
    .hs__features-container__feature {
        flex-direction: column;
    }
    .hs__features-container_feature-text p {
        font-family: var(--font-family);
        font-weight: 200;
        font-size: 16px;
        line-height: 22px;
        color: var(--color-text);
    }    
}

@media screen and (max-width: 550px) {
    .hs__features-container__feature {
        flex-direction: column;
    }
    .hs__features-container_feature-text {
        font-family: var(--font-family);
        font-weight: 200;
        font-size: 16px;
        line-height: 22px;
        color: var(--color-text);
        min-width: 120px;
    }
}