@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');

@font-face {
  font-family: 'Roboto';
  src: url(../src/assets/Roboto-Regular.ttf) format('truetype');
}

:root {
  --font-family: 'Paytone One';
  --font-family: 'Roboto';

  --gradient-text: linear-gradient(89.97deg, #CFFF86 1.84%, #14FFFF 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #CFFF86 -13.86%, #14FFFF 99.55%);
  --gradient-bg: -webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #fff;
  --color-subtext: #FF8A71;
}