.hs__features {
    display: flex;
    flex-direction: row;
    margin: 6em;
    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.hs__features-heading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 6rem;
}

.hs__features-heading div{
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}

.hs__features-heading h2 {
    font-family: 'Paytone One';
    font-weight: 500;
    font-size: 38px;
    line-height: 38px;
    color: #fff;
}

.hs__features-heading a {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #D4AF37;
    margin-top: 2rem;
}

.hs__features-heading p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    margin-top: 2rem;
    letter-spacing: 0.6px;
}

.hs__features-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 1250px) {
    .hs__features {
        flex-direction: column;
    }
    .hs__features-heading {
        margin: 0rem 0 2rem 0;
    }
    .hs__features-heading h2 {
        font-size: 25px;
        line-height: 38px;
    }
    .hs__features-heading a {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #D4AF37;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 750px) {
    .hs__features {
        flex-direction: column;
        margin: 2em;
        background: var(--color-footer);
    }
}