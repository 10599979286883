body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 0.5rem;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}

.video-responsive {
    overflow: hidden;
    position: relative;
    height: 0;
}

.modal-content iframe {
    height: 100%;
    width: 100%;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #040C18;
    padding: 28px;
    border-radius: 1px;
    width: 60%;
    height: 70vh;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: -70px;

    font-family: var(--font-family);
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    cursor: pointer;
}

@media screen and (max-width: 1050px) {
    .modal-content {
        width: 50%;
        height: 40vh;
    }
}

@media screen and (max-width: 750px) {
    .modal-content {
        width: 80%;
        height: 50vh;
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        width: 100%;
        height: 40vh;
    }
}