.hs__projects-container_article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.hs__projects-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-footer);
}

.hs__projects-container_article-image img {
    width: 100%;
    height: 100%;
}

.hs__projects-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 100%;
}

.hs__projects-container_article-content a{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.hs__projects-container_article-content p {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
}

.hs__projects-container_article-content h3 {
    font-family: 'Paytone One';
    font-weight: 300;
    font-size: 25px;
    line-height: 25px;
    color: #ffffff;
    margin-bottom: 1rem;
    cursor: pointer;
}

.hs__projects-container_article-content p:last-child {
    cursor: pointer;
    margin-top: 0.5rem;
}

.hs__projects-container_article-content h4 {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 18px;
    font-weight: 100;
    color: #14FFFF;
    padding-bottom: 2rem;
}

@media screen and (max-width: 550px) {
    .hs__projects-container_article-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        height: 100%;
    }
    .hs__projects-container_article-content h3 {
        font-size: 20px;
        line-height: 25px;
    }
    .hs__projects-container_article-content p {
        font-family: var(--font-family);
        font-size: 16px;
        line-height: 18px;
        color: #ffffff;
    }
    .hs__projects-container_article-content h4 {
        font-family: var(--font-family);
        font-size: 16px;
        line-height: 18px;
    }
}