.hs__about {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -4rem;
    margin-bottom: -2rem;
}

.hs__about-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: auto;
}

.hs__about-content h2 {
    font-family: 'Paytone One';
    font-weight: 500;
    font-size: 62px;
    line-height: 62px;
    margin: 0.25rem 0 2rem;
}

.hs__about-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #D4AF37;
}

.hs__about-content a {
    cursor: pointer;
}


.hs__about-content p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 22px;
    color: var(--color-text);
    width: 600px;
    margin-bottom: 2rem;
    letter-spacing: 0.6px;
}

.hs__about-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hs__about-image img {
    width: 70%;
    height: 100%;
}

@media screen and (max-width: 1080px) {
    .hs__about {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .hs__about-image {
        margin: 1rem 0;
    }

    .hs__about-content {
        margin-top: 0.5rem;
        align-items: center;
        text-align: center;
    }

    .hs__about-content h2{
        text-align: center;
        font-size: 42px;
    }

    .hs__about-content h4{
        text-align: center;
        font-size: 16px;
    }

    .hs__about-content p {
        font-family: var(--font-family);
        font-size: 16px;
        line-height: 30px;
        color: var(--color-text);
        text-align: center;
        margin-bottom: 2rem;
        width: auto;
    }
}